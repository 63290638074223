import { IconButton, Typography } from "@mui/material";
import "./Footer.css";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";

export default function Footer() {
  return (
    <footer className="footerContainer">
      <Typography className="footerCompanyNameText">
        © Craftsmen Studios, Chennai, India
      </Typography>
      <div>
        <IconButton
          color="primary"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.facebook.com/craftsmenstudios"
        >
          <FacebookIcon />
        </IconButton>
        <IconButton
          color="primary"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/craftsmenstudios"
        >
          <InstagramIcon />
        </IconButton>
        <IconButton
          color="primary"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.youtube.com/@CraftsmenStudios"
        >
          <YouTubeIcon />
        </IconButton>
      </div>
    </footer>
  );
}
