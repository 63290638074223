import "./GalleryDetail.css";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Grid, CardMedia } from "@mui/material";
import LoaderView from "../Component/LoaderView";

export default function GalleryDetail() {
  const { id } = useParams();

  const [dataList, setDataList] = useState(null);

  useEffect(() => {
    console.log(id);
    fetch(`/json/${id}.json`)
      .then((response) => response.json())
      .then((data) => {
        setDataList(data);
      })
      .catch((error) => console.error("Error fetching the JSON file:", error));
  });

  if (!dataList) {
    return <LoaderView />;
  }
  return (
    <div className="GalleryDetailContainer">
      <Grid container spacing={3} className="gridGalleryDetailContainer">
        {dataList.clientPhotos.map((item) => (
          <Grid key={item.id} item xs={12}>
            <CardMedia
              component="img"
              className="galleryDetailMedia"
              image={item.url}
              alt={dataList.clientName}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
