import { Grid, CardActionArea, CardMedia } from "@mui/material";

export default function ImageGridView(props) {
  return (
    <Grid container spacing={props.spacing}>
      {props.data.map((item) => (
        <Grid key={item.id} item xs={12} sm={4}>
          <CardActionArea onClick={() => props.onClickCard(item)}>
            <CardMedia
              component="img"
              image={item.clientThumbnail}
              alt={item.clientName}
              style={{ pointerEvents: "none" }}
            />
          </CardActionArea>
        </Grid>
      ))}
    </Grid>
  );
}
