import "./Header.css";
import { useState, useEffect } from "react";
import {
  Box,
  AppBar,
  Toolbar,
  Button,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";

export default function Header() {
  // let info = "#ffc974"

  const location = useLocation();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const isActive = (path) => location.pathname === path;

  const handleClick = (event) => {
    console.log(event);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (string) => {
    navigate(string);
    setAnchorEl(null);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    handleResize();
  });

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });

  const menuOption = (
    <div>
      <IconButton
        size="large"
        edge="start"
        color="info"
        aria-label="menu"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{ mr: 2 }}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem selected={isActive("/")} onClick={() => handleClose("/")}>
          Home
        </MenuItem>
        <MenuItem
          selected={isActive("/gallery")}
          onClick={() => handleClose("/gallery")}
        >
          Gallery
        </MenuItem>
        <MenuItem
          selected={isActive("/films")}
          onClick={() => handleClose("/films")}
        >
          Films
        </MenuItem>
        <MenuItem
          selected={isActive("/aboutus")}
          onClick={() => handleClose("/aboutus")}
        >
          About us
        </MenuItem>
        <MenuItem
          selected={isActive("/booknow")}
          onClick={() => handleClose("/booknow")}
        >
          Book Now
        </MenuItem>
      </Menu>
    </div>
  );

  return (
    <Box sx={{ backgroundColor: "#03386a" }}>
      <AppBar
        sx={{ bgcolor: "#03386a", opacity: 0.8 }}
        position="fixed"
        elevation={0}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <a href="https://craftsmenstudios.in">
            <img src="/logo-white.png" alt="logo" className="App-logo" />
          </a>
          {isMobile ? (
            menuOption
          ) : (
            <Box>
              <Button
                color={isActive("/") ? "secondary" : "info"}
                size="large"
                component={Link}
                sx={{
                  margin: "7px",
                  fontWeight: isActive("/") ? "600" : "500",
                }}
                to="/"
              >
                Home
              </Button>
              <Button
                color={isActive("/gallery") ? "secondary" : "info"}
                component={Link}
                sx={{
                  margin: "7px",
                  fontWeight: isActive("/gallery") ? "600" : "500",
                }}
                to="/gallery"
              >
                Gallery
              </Button>
              <Button
                color={isActive("/films") ? "secondary" : "info"}
                component={Link}
                sx={{
                  margin: "7px",
                  fontWeight: isActive("/films") ? "600" : "500",
                }}
                to="/films"
              >
                Films
              </Button>
              <Button
                color={isActive("/aboutus") ? "secondary" : "info"}
                component={Link}
                sx={{
                  margin: "7px",
                  fontWeight: isActive("/aboutus") ? "600" : "500",
                }}
                to="/aboutus"
              >
                About us
              </Button>
              <Button
                color={isActive("/booknow") ? "secondary" : "info"}
                component={Link}
                sx={{
                  margin: "7px",
                  fontWeight: isActive("/booknow") ? "600" : "500",
                }}
                to="/booknow"
              >
                Book Now
              </Button>
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
