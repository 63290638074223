import "./App.css";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import HomePage from "./Home/Home";
import GalleryPage from "./Gallery/Gallery";
import GalleryDetailPage from "./GalleryDetail/GalleryDetail";
import FilmsPage from "./Films/Films";
import BookNowPage from "./BookNow/BookNow";
import AboutUsPage from "./AboutUs/AboutUs";

import { HashRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#ffc974",
    },
    info: {
      main: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Lato=", // Replace 'YourFontFamily' with your desired font
    // You can also customize other typography properties here
    h1: {
      fontFamily: "Lato",
    },
    h3: {
      fontFamily: "Lato",
    },
    h4: {
      fontFamily: "Lato",
    },
    h5: {
      fontFamily: "Lato",
    },
    button: {
      fontFamily: "Lato",
    },
    body1: {
      fontFamily: "Lato",
    },
    cardContent: {
      fontFamily: "Lato",
    },
    CardMedia: {
      fontFamily: "Lato",
    },
    menuItem: {
      fontFamily: "Lato",
    },
  },
});

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <HashRouter>
          <Header />
          <main className="App-content">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/gallery" element={<GalleryPage />} />
              <Route path="/gallery/:id" element={<GalleryDetailPage />} />
              <Route path="/films" element={<FilmsPage />} />
              <Route path="/aboutus" element={<AboutUsPage />} />
              <Route path="/booknow" element={<BookNowPage />} />
            </Routes>
          </main>
          <Footer />
        </HashRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
