import { Typography, List, ListItem } from "@mui/material";
import "./AboutUs.css";

export default function AboutUs() {
  return (
    <div className="aboutUsContainer">
      <div style={{ margin: "20px 0px" }}>
        <Typography variant="h5" fontWeight={500}>
          Hi,
        </Typography>
        <Typography sx={{ marginTop: "10px", whiteSpace: "pre-wrap" }}>
          {"\t"}Welcome to Craftsmen Studios, where we believe that every love
          story is unique and deserves to be beautifully captured. As a premier
          wedding photography service, our mission is to tell your love story
          through stunning visuals that you will cherish for a lifetime.
        </Typography>
      </div>
      <div style={{ margin: "30px 0px" }}>
        <Typography variant="h5" fontWeight={500}>
          Our story
        </Typography>
        <Typography sx={{ marginTop: "10px", whiteSpace: "pre-wrap" }}>
          {"\t"}Craftsmen Studios was born out of a passion for capturing the
          magic of love and the essence of life’s most cherished moments. With
          over 5+ years of experience in the photography industry, Our team has
          developed a keen eye for detail and an artistic touch that transforms
          ordinary moments into extraordinary memories.
        </Typography>
      </div>

      <div style={{ margin: "30px 0px" }}>
        <Typography variant="h5" fontWeight={500}>
          Our Philosophy
        </Typography>
        <Typography sx={{ marginTop: "10px", whiteSpace: "pre-wrap" }}>
          {"\t"}At Craftsmen Studios, we understand that your wedding day is one
          of the most important days of your life. Our approach is to blend into
          the background, allowing the day to unfold naturally while we capture
          every candid smile, joyful tear, and heartfelt embrace. We strive to
          create a relaxed and enjoyable experience for our clients, ensuring
          that your personality and love story shine through in every
          photograph.
        </Typography>
      </div>

      <div style={{ margin: "30px 0px" }}>
        <Typography variant="h5" fontWeight={500}>
          Our Services
        </Typography>
        <Typography>
          We offer a range of services to suit your needs, including:
        </Typography>
        <List
          sx={{ listStyleType: "disc", marginLeft: "30px", padding: "0px" }}
        >
          <ListItem sx={{ display: "list-item" }}>
            <strong>Engagement Sessions:</strong> Capture the excitement and
            anticipation of your engagement with a personalized photo session.
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <strong>Wedding Day Photography:</strong> From the intimate moments
            of getting ready to the exuberant celebrations of your reception, we
            cover every aspect of your special day.
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <strong>Baby Shoot Sessions:</strong> Celebrate the joy of your new
            addition with adorable and heartwarming baby photography.
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <strong>Maternity Shoot Sessions:</strong> Embrace the beauty of
            your pregnancy with a maternity shoot that highlights this special
            time in your life.
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <strong>Model Shoot Sessions:</strong>Showcase your style and
            personality with professional model photography that stands out.
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <strong>Custom Albums & Prints:</strong>Preserve your memories in
            beautiful, high-quality albums and prints that you can treasure
            forever.
          </ListItem>
        </List>
      </div>

      <div style={{ margin: "30px 0px" }}>
        <Typography variant="h5" fontWeight={500}>
          Our Commitment
        </Typography>
        <Typography sx={{ marginTop: "10px", whiteSpace: "pre-wrap" }}>
          {"\t"}We are committed to providing you with exceptional service and
          breathtaking photographs that you will love. Our dedication to
          quality, creativity, and professionalism ensures that your photos will
          be a timeless testament to your story.
        </Typography>
      </div>

      <div style={{ margin: "30px 0px" }}>
        <Typography variant="h5" fontWeight={500}>
          Meet the Team
        </Typography>
        <Typography sx={{ marginTop: "10px", whiteSpace: "pre-wrap" }}>
          {"\t"}Our team of talented photographers and editors work together to
          deliver stunning images that capture the essence of your special
          moments. Each member of our team brings their unique skills and
          artistic vision, creating a collaborative and dynamic environment that
          enhances our work.
        </Typography>
      </div>

      <div style={{ margin: "30px 0px" }}>
        <Typography variant="h5" fontWeight={500}>
          Let's Connect
        </Typography>
        <Typography sx={{ marginTop: "10px", whiteSpace: "pre-wrap" }}>
          {"\t"}We would love to hear more about your plans and discuss how we
          can help make your moments unforgettable. Please feel free to contact
          us to schedule a consultation or ask any questions you may have.
        </Typography>
      </div>

      <div style={{ margin: "50px 0px" }}>
        <Typography>
          Thank you for considering <strong>Craftsmen Studios</strong> for your
          photography needs. We look forward to being a part of your story!
        </Typography>
      </div>
    </div>
  );
}
