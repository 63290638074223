import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  Button,
} from "@mui/material";
import { useState } from "react";
import "./BookNow.css";
import emailjs from "emailjs-com";

export default function BookNow() {
  const [formData, setFormData] = useState({
    user_name: "",
    user_email: "",
    user_phone: "",
    user_date: "",
    user_venue: "",
    user_eventType: "",
    user_budget: "",
    user_hearAboutUs: "",
  });

  const [sentFailed, setSentFailed] = useState(false);
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);

  const onChangeText = (event) => {
    const { name, value } = event.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Validate form data
  const validate = () => {
    const newErrors = {};

    if (!formData.user_name) {
      newErrors.user_name = "Name is required";
    }

    if (!formData.user_email) {
      newErrors.user_email = "Email is required";
    }

    if (!formData.user_phone) {
      newErrors.user_phone = "Phone Number is required";
    }

    if (!formData.user_date) {
      newErrors.user_date = "Date of the Event is required";
    }

    if (!formData.user_venue) {
      newErrors.user_venue = "Venues is required";
    }

    if (!formData.user_eventType) {
      newErrors.user_eventType = "Type of Event is required";
    }

    if (!formData.user_budget) {
      newErrors.user_budget = "Required";
    }

    if (!formData.user_hearAboutUs) {
      newErrors.user_hearAboutUs = "Required";
    }

    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      emailjs
        .send(
          "service_7345pci",
          "email_template_1",
          formData,
          "wz2cT3HTPOErnlq0T"
        )
        .then(
          (response) => {
            setOpen(true);
            setFormData({
              user_name: "",
              user_email: "",
              user_phone: "",
              user_date: "",
              user_venue: "",
              user_eventType: "",
              user_budget: "",
              user_hearAboutUs: "",
            });
          },
          (error) => {
            setSentFailed(true);
            setOpen(true);
          }
        );
    }
  };

  const handleAlertClose = () => {
    setOpen(false);
  };

  return (
    <div className="bookNowContainer">
      <Snackbar open={open} autoHideDuration={8000} onClose={handleAlertClose}>
        <Alert
          onClose={handleAlertClose}
          severity={sentFailed ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {sentFailed
            ? "Failed to send message. Please try again."
            : "Message sent successfully!"}
        </Alert>
      </Snackbar>
      <img
        src="/documents/carousel/1.jpg"
        alt="welcome"
        className="bookNowCoverImage"
      />
      <div className="bookNowTitle">Let's begin our journey!</div>
      <Box
        onSubmit={handleSubmit}
        component="form"
        noValidate
        autoComplete="off"
        className="booknowTextContainer"
      >
        <div>
          <TextField
            id="standard"
            label="Name"
            name="user_name"
            onChange={onChangeText}
            required
            fullWidth
            variant="standard"
            value={formData.user_name}
            sx={{ margin: "15px 0px" }}
            error={!!errors.user_name}
            helperText={errors.user_name}
          />
          <TextField
            id="standard"
            label="Email Address"
            name="user_email"
            onChange={onChangeText}
            required
            fullWidth
            variant="standard"
            value={formData.user_email}
            sx={{ margin: "15px 0px" }}
            error={!!errors.user_email}
            helperText={errors.user_email}
          />
          <TextField
            id="standard"
            label="Phone Number"
            name="user_phone"
            onChange={onChangeText}
            required
            fullWidth
            variant="standard"
            value={formData.user_phone}
            sx={{ margin: "15px 0px" }}
            error={!!errors.user_phone}
            helperText={errors.user_phone}
          />
          <TextField
            id="standard"
            label="Date of the Event"
            placeholder="dd/mm/yyyy"
            name="user_date"
            onChange={onChangeText}
            required
            fullWidth
            variant="standard"
            value={formData.user_date}
            sx={{ margin: "15px 0px" }}
            error={!!errors.user_date}
            helperText={errors.user_date}
          />
          <TextField
            id="standard"
            label="Venues"
            name="user_venue"
            onChange={onChangeText}
            required
            fullWidth
            variant="standard"
            value={formData.user_venue}
            sx={{ margin: "15px 0px" }}
            error={!!errors.user_venue}
            helperText={errors.user_venue}
          />
          <FormControl
            fullWidth
            sx={{ margin: "15px 0px" }}
            error={!!errors.user_eventType}
            helperText={errors.user_eventType}
          >
            <InputLabel id="demo-simple-select-label" required>
              Type of Event
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              variant="standard"
              required
              label="Type of Event"
              name="user_eventType"
              value={formData.user_eventType}
              onChange={onChangeText}
            >
              <MenuItem value="Baby Shoot">Baby Shoot</MenuItem>
              <MenuItem value="Birthday">Birthday</MenuItem>
              <MenuItem value="Engagement">Engagement</MenuItem>
              <MenuItem value="Maternity">Maternity</MenuItem>
              <MenuItem value="Model">Model</MenuItem>
              <MenuItem value="Pre-Wedding">Pre-Wedding</MenuItem>
              <MenuItem value="Wedding">Wedding</MenuItem>
            </Select>
          </FormControl>
          <TextField
            id="standard"
            label="Do you have a budget in mind? :)"
            name="user_budget"
            onChange={onChangeText}
            required
            fullWidth
            variant="standard"
            value={formData.user_budget}
            sx={{ margin: "15px 0px" }}
            error={!!errors.user_budget}
            helperText={errors.user_budget}
          />
          <TextField
            id="standard"
            label="How did you hear about us ?"
            name="user_hearAboutUs"
            onChange={onChangeText}
            required
            fullWidth
            variant="standard"
            value={formData.user_hearAboutUs}
            sx={{ margin: "15px 0px" }}
            error={!!errors.user_hearAboutUs}
            helperText={errors.user_hearAboutUs}
          />
        </div>
        <Button
          sx={{
            margin: "20px 0px",
            backgroundColor: "#03386a",
            ":hover": {
              backgroundColor: "#355F87", // theme.palette.primary.main
              color: "white",
            },
          }}
          type="submit"
          variant="contained"
        >
          Submit
        </Button>
      </Box>
    </div>
  );
}
