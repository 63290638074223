import { CircularProgress, Box } from "@mui/material";

export default function LoaderView() {
  return (
    <Box
      sx={{
        display: "flex",
        paddingTop: "130px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress size={80} sx={{ color: "#0052cc" }} />
    </Box>
  );
}
