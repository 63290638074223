import "./Films.css";
import React, { useState, useEffect, Suspense, lazy } from "react";
import { CircularProgress, Grid } from "@mui/material";
import LoaderView from "../Component/LoaderView";

const LazyYouTube = lazy(() => import("../Component/YouTubeComponent"));

export default function Films() {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch("/json/films.json")
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.error("Error fetching the JSON file:", error));
  }, []);

  if (!data) {
    return <LoaderView />;
  }

  return (
    <div className="FilmsGridContainer">
      <Grid className="FilmsYoutubeContainer" container rowGap={3}>
        {data.map((item) => (
          <Grid key={item.id} xs={12} sm={6}>
            <Suspense key={item.id} fallback={<CircularProgress />}>
              <LazyYouTube videoId={item.videoId} isFilms />
            </Suspense>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
