import "./Gallery.css";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoaderView from "../Component/LoaderView";
import ImageGridView from "../Component/ImageGridView";

export default function Gallery() {
  const navigate = useNavigate();

  const [data, setData] = useState(null);

  useEffect(() => {
    fetch("/json/gallery.json")
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.error("Error fetching the JSON file:", error));
  }, []);

  const onClickCard = (item) => {
    navigate(`/gallery/${item.clientId}`);
  };

  if (!data) {
    return <LoaderView />;
  }

  return (
    <div className="GalleryGridContainer">
      <ImageGridView
        data={data}
        spacing={3}
        onClickCard={(item) => onClickCard(item)}
      />
    </div>
  );
}
