import { Button, Grid, Typography, CircularProgress } from "@mui/material";
import "./Home.css";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, Suspense, lazy } from "react";
import LoaderView from "../Component/LoaderView";
import ImageGridView from "../Component/ImageGridView";

const LazyYouTube = lazy(() => import("../Component/YouTubeComponent"));

export default function Home() {
  const navigate = useNavigate();
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch("/json/home.json")
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.error("Error fetching the JSON file:", error));
  }, []);

  if (!data) {
    return <LoaderView />;
  }

  const onClickCard = (item) => {
    navigate(`/gallery/${item.clientId}`);
  };

  return (
    <div style={{ paddingTop: "100px" }}>
      <Carousel
        interval={1800}
        indicators={false}
        keyboard={false}
        className="CarouselContainer"
      >
        {data.carouselList.map((item) => (
          <Carousel.Item key={item.id}>
            <img src={item.url} alt={item.name} className="CarouselImage" />
          </Carousel.Item>
        ))}
      </Carousel>
      <div className="GridContainer">
        <ImageGridView
          data={data.gridContentList}
          spacing={5}
          onClickCard={(item) => onClickCard(item)}
        />
      </div>
      <div className="GalleryContainer">
        <Typography align="center">
          we capture the magic of your wedding day with breathtaking photographs
          that tell your love story. Our approach blends artistic creativity
          with a keen eye for detail, ensuring that every moment, from the grand
          to the intimate, is beautifully preserved. We focus on the emotions,
          the joy, and the spontaneous moments that make your wedding day
          uniquely yours. Our team is dedicated to providing a seamless
          experience, capturing everything from the quiet anticipation before
          the ceremony to the exuberant celebrations of the reception. With a
          passion for storytelling, we create a visual narrative that reflects
          the essence of your relationship. Our wedding photos are more than
          just images; they are timeless memories that you will cherish forever.
        </Typography>
        <Button
          sx={{ backgroundColor: "#1aa3ff", color: "white", margin: "15px" }}
          component={Link}
          to="/gallery"
        >
          Gallery
        </Button>
      </div>
      <div className="WeddingFilmsTextContainer">
        <Typography
          align="center"
          variant="body2"
          fontFamily="Roboto"
          fontWeight={800}
          fontSize={40}
        >
          Wedding Films
        </Typography>
        <Typography align="center" color="#666666" sx={{ margin: "20px 0px" }}>
          we specialize in creating candid wedding films that capture the true
          essence of your special day. Our films are crafted with a focus on
          authentic moments, heartfelt emotions, and spontaneous joy. We believe
          the best stories are told through unscripted, genuine interactions,
          and our unobtrusive approach ensures we capture every intimate glance
          and joyful tear. Each film we create is a timeless piece, reflecting
          the unique love story of every couple. Our passion lies in preserving
          these precious moments, allowing you to relive the magic of your
          wedding day for years to come. Trust us to document your celebration
          with creativity and care. Let us turn your wedding into a beautiful,
          cinematic experience that you’ll cherish forever.
        </Typography>
      </div>
      <div className="WeddingFilmsContainer">
        <Grid className="YoutubeContainer" container rowGap={3}>
          {data.youtubeList.map((item) => (
            <Grid key={item.id} xs={12} sm={6}>
              <Suspense key={item.id} fallback={<CircularProgress />}>
                <LazyYouTube videoId={item.videoId} />
              </Suspense>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
}
